import { createGlobalStyle } from 'styled-components';
import { media } from './media';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    /* Dropdown panel is added to body not root so styling has to be in here */
    .p-dropdown-panel {
      z-index: 101 !important;
    }
    .p-dialog-mask {
      z-index: 100 !important;
    }
    --primary-color: #fff;
    --secondary-color: #000;
    --background-color: #fff;
    --border-color: #000;
    --border-color-secondary: rgba(0, 0, 0, 0.2);
    --text-color: #000;
    --mask-color: #f5f5f5e0;
    --message-info-color: #e8e3ef;
    --message-error-color: #f8d7d5;
    --message-warn-color: #f8e8d5;
  }
  
  #root {
    --sidebar-width: 0px;
    --sidebar-width-mobile: 100%;
    --sidebar-dialog-width: 0px;
    --sidebar-display: none;
    --menu-display: none;
    --sidebar-connection-display: none;
    --sidebar-source-display: none;
    min-height: 100%;
    min-width: 100%;
    /* White flashing on dark mode is because the js hasn't switch the theme yet */
    /* A much better solution is to use the perfer dark mode to set theme on load. */
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
    .p-dropdown-label {
      padding: 0.5rem;
      font-size: 1.1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .p-dropdown, .dialectSelect {
      margin: 0.5rem 0 !important;
      background-color: transparent !important;
      ${media.mobile} {
        margin: 0.5rem 0 !important;
        width: min(60vw, 20rem) !important;
      }
      ${media.small} {
        margin: 0.5rem 0 !important;
        width: min(95vw, 40rem) !important;
      }
    }

    .error {
      border: 3px solid var(--message-error-color) !important;
    }

    .show {
      display: block !important;  
    }

    .p-component::placeholder {
      color: var(--border-color) !important;
    }
    .p-component::-webkit-input-placeholder {
      color: var(--border-color) !important;
    }
    .p-component::-moz-placeholder {
      color: var(--border-color) !important;
    }
    .p-component:hover {
      border-color: var(--border-color) !important;
    }
    .p-component:focus {
      border-color: var(--border-color) !important;
    }
    .p-component:focus-within {
      border-color: var(--border-color) !important;
    }
    .p-component:active {
      border-color: var(--border-color) !important;
    }
    .p-component:enabled:focus {
      box-shadow: 0 0 0 1px var(--message-info-color) !important;
    }
    .p-component {
      color: var(--text-color) !important;
    }
    .p-inputgroup .p-textinput {
        padding: 0.5rem !important;
        color: #000;
    }
    .p-inline-message {
      margin: 0.5rem 0 !important;
      padding: 0.5rem !important;
      font-size: 0.8rem;
      border-radius: 0.5rem !important;
      justify-content: start !important;
      width: 100% !important;
      color: var(--text-color);
      svg {
        width: 15px !important;
        height: 15px !important;
        margin-right: 0.5rem !important;
      }
      span {
        width: 100% !important;
      }
    }
    .p-datatable-emptymessage {
      td {
        padding: 0.5rem;
        padding-top: 1rem;
      }
    }
    .p-datatable-thead {
      border-bottom: 1px solid var(--border-color-secondary) !important;
    }
    .p-datatable-tbody {
      td:first-child {
        border-radius: 0.5rem 0 0 0.5rem !important;
        }
      td:last-child {
        border-radius: 0 0.5rem 0.5rem 0 !important;
        }
    }
    .p-datatable:hover {
      border-color: var(--border-color-secondary) !important;
    }
    .p-column-header-content {
      font-size: 0.8rem !important;
      ${media.small} {
        font-size: 0.8rem !important;
      }
      span {
        margin-right: 0.5rem !important;
      }
      svg {
        min-width: 12px !important;
        min-height: 12px !important;
      }
    }
    .p-sortable-column, .p-highlight {
      padding: 0.5rem;
      padding-right: 0.5rem !important;
    }
    .p-row-odd {
      background-color: var(--border-color-secondary) !important;
    }
    .p-inline-message-info {
      background-color: var(--message-info-color) !important;
    }
    .p-inline-message-error {
      background-color: var(--message-error-color) !important;
    }
    .p-inline-message-warn {
      background-color: var(--message-warn-color) !important;
    }
    .p-inline-message-icon {
      svg {
        color: var(--text-color);
        margin-right: 0.5rem !important;
        width: 20px !important;
        height: 20px !important;
      }
    }

    .sidebarSlide {
      width: var(--sidebar-width);
      transition: width 0.5s ease-in-out;
    }

      @keyframes slideIn {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes slideOut {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }


  }
  
  .p-dialog-mask {
    background-color: var(--mask-color) !important;
  }
  .p-dialog {
    border: 1px solid var(--border-color) !important;
    padding: 1rem !important;
    padding-top: 0.5rem !important;
    max-height: 40rem;
    max-width: 50rem;
    background-color: var(--background-color) !important;
  }
  .p-dialog-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
  }
  .p-dialog-header {
    border-bottom: 2px solid var(--border-color) !important;
    padding: 0.2rem !important;
    color: var(--text-color);
  }
  .p-dialog-header-icon {
    padding: 0.5rem !important;
    color: var(--text-color);
  }
  .p-dialog-content {
    background-color: var(--mask-color) !important;
    padding: 0.5rem !important;
    border-radius: 0 0 0.5rem 0.5rem !important;
    border: 1px solid var(--border-color) !important;
    border-top: none !important;
    color: var(--text-color);
  }
  .p-dialog-maximized {
    width: 100% !important;
    max-width: 100% !important;
    height: 100dvh !important;
    max-height: 100dvh !important;
    top: 0 !important;
    left: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    padding-top: 1rem !important;
  }

  .p-dropdown-label {
    ${media.mobile} {
      padding: 0.3rem 0 !important;
    }
    ${media.small} {
      padding: 0.5rem;
    }
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-color);
  }
  .p-dropdown-trigger {
    color: var(--text-color) !important;
  }

  .searchButton {
    border-radius: 0rem 0.5rem 0.5rem 0 !important;
  }

  .searchInput {
    border-radius: 0.5rem 0 0 0.5rem !important;
  }

  .p-button {
    border-radius: 0.5rem;
  }

  .p-button-icon-only svg {
    color: var(--background-color) !important;
  }

  .p-inputgroup .p-textinput {
        padding: 0.5rem !important;
  }

  .p-dialog-header {
    color: var(--text-color) !important;
    background-color: var(--background-color) !important;
  }

  .p-dialog-header-icon {
    color: var(--text-color) !important;
  }

  .p-dropdown-item-wrapper {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
  }

  .p-dropdown-item {
    background-color: transparent;
    color: var(--text-color) !important;
  }
  .p-dropdown:enabled:focus {
      /* box-shadow: 0 0 0 1px var(--message-info-color) !important; */
      box-shadow: 0 0 0 1px var(--border-color) !important;
  }
  .p-dropdown {
    background-color: var(--background-color) !important;
  }
  .p-focus {
    box-shadow: 0 0 0 1px var(--border-color-secondary) !important;
  }
  .p-highlight {
    background-color: var(--border-color-secondary) !important;
  }
  .p-button:enabled:hover, .p-button:enabled:focus {
    background-color: var(--border-color-secondary) !important;
  }

  code {
    color: var(--text-color);
  }

  .p-dialog-content {
    min-height: 15rem;
    min-width: min(90vw, 40rem);
  }

  tr {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
  }
  
  td {
    padding: 0.5rem !important;
    border-color: transparent !important;
  }

  tr::nth-child(odd) {
    background-color: var(--border-color-secondary) !important;
  }

  th {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
    padding: 0.5rem !important;
  }

  th svg {
    color: var(--text-color) !important;
  }

  .p-dropdown-panel {
    background-color: #eee !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 0.5rem !important;
    ${media.mobile} {
      margin: 0 1rem !important;
    }
    ${media.small} {
      margin: initial !important;
    }
  }

  .p-dropdown-item, .p-highlight, .p-dropdown-item-empty {
      font-size: 0.8rem;
      padding: 0.5rem !important;
    }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;
