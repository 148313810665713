import React from 'react';
import styled from 'styled-components';
import { media } from 'styles/media';
import axios from 'axios';

import { auth, currentUserId } from 'utils/firebase-init';
import { notifyMe } from 'utils/notify';

import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
  TbMenu2,
  TbSunFilled,
  TbLogout,
} from 'react-icons/tb';

const AccountWrapper = styled.div`
  display: inline-flex;
  height: 3rem;
  align-content: center;
  white-space: nowrap;
  ${media.mobile} {
    margin: 0 0.5rem;
    margin-left: auto;
    a,
    p {
      font-size: 0.85rem;
    }
    .hide-mobile {
      display: none;
    }
  }
  ${media.small} {
    margin: 0rem 1.5rem;
    margin-left: auto;
    .hide-mobile {
      display: inline-flex;
    }
  }
`;

function AccountMenu(user: any) {
  const [name, setName] = React.useState(null as any);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(true);
    if (user.user) {
      if (name !== user.user.displayName) {
        setName(user.user.displayName);
      }
    } else {
      setName('User');
    }
    setLoading(false);
  }, [name, user.user]);

  return (
    <>
      <div
        id="menu"
        style={{
          position: 'absolute',
          display: 'var(--menu-display)',
          height: 'min(100%, 20rem)',
          width: 'min(100%, 15rem)',
          right: '0',
          top: '3rem',
          zIndex: 101,
          border: '1px solid var(--border-color-secondary)',
          borderRadius: '0 0 0 0.5rem',
          padding: '0.5rem',
          backgroundColor: 'var(--background-color)',
        }}
      >
        {loading && (
          <div
            style={{
              display: 'inline-flex',
              height: '3rem',
              alignContent: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <p
              style={{
                margin: 'auto 0.5rem',
                fontSize: '0.85rem',
                fontWeight: 600,
                color: 'var(--text-color)',
                fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              Loading...
            </p>
          </div>
        )}
        {!loading && user && (
          <div
            style={{
              display: 'block',
              height: '100%',
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                height: 'auto',
                width: '100%',
              }}
            >
              <p
                style={{
                  margin: '0.5rem',
                  height: '1.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 600,
                  color: 'var(--text-color)',
                  fontFamily:
                    "Playfair Display, 'Times New Roman', Times, serif",
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </p>
              <button
                id="signout"
                onClick={() => {
                  auth.signOut();
                  window.location.href = '/';
                }}
                style={{
                  width: 'auto',
                  height: '1.5rem',
                  margin: '0.5rem',
                  backgroundColor: 'transparent',
                  marginLeft: 'auto',
                  border: 'none',
                  padding: 0,
                }}
              >
                <TbLogout
                  style={{
                    color: 'var(--text-color)',
                    height: '1.5rem',
                    width: '1.5rem',
                    cursor: 'pointer',
                  }}
                />
              </button>
            </div>
            <ul
              style={{
                display: 'block',
                fontSize: '1rem',
                fontWeight: 600,
                color: 'var(--text-color)',
                fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                padding: '1rem',
                margin: '0',
                textDecoration: 'underline',
                height: '100%',
              }}
            >
              <li
                style={{
                  display: 'block',
                  width: '100%',
                  paddingBottom: '1rem',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
              >
                <a
                  href="/account"
                  style={{
                    margin: '0',
                    padding: '0',
                    fontWeight: 600,
                    color: 'var(--text-color)',
                    fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Account
                </a>
              </li>
              <li
                style={{
                  display: 'block',
                  width: '100%',
                  paddingBottom: '1rem',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
              >
                <a
                  href="/sources"
                  style={{
                    margin: '0',
                    padding: '0',
                    fontWeight: 600,
                    color: 'var(--text-color)',
                    fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Sources
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

function Sidebar(pagename: { pagename: string }) {
  const [theme, setTheme] = React.useState('light' as 'light' | 'dark');
  React.useEffect(() => {
    const localTheme = sessionStorage.getItem('theme');
    if (localTheme) {
      swapTheme(localTheme);
      setTheme(localTheme as 'light' | 'dark');
    } else {
      swapTheme('light');
      setTheme('light');
    }
  }, []);

  return (
    <div
      className=""
      style={{
        display: 'var(--sidebar-display)',
        position: 'fixed',
        backgroundColor: 'var(--background-color)',
        height: '100%',
        width: '15rem',
        transform: 'translateX(calc(var(--sidebar-width) - 15rem))',
        borderRight: '1px solid var(--border-color-secondary)',
        zIndex: 100,
      }}
    >
      <div
        style={{
          position: 'absolute',
          display: 'block',
          height: '3rem',
          width: 'var(--sidebar-width)',
        }}
      >
        <p
          title={pagename.pagename}
          style={{
            position: 'absolute',
            margin: '0 1rem',
            marginTop: '0.6rem',
            fontSize: '1.2rem',
            fontWeight: 600,
            color: 'var(--text-color)',
            fontFamily: "Playfair Display, 'Times New Roman', Times, serif",
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100% - 4rem)',
          }}
        >
          {pagename.pagename}
        </p>
        <button
          id="sidebar-collapse"
          onClick={() => {
            var width = 15;
            var id = setInterval(frame, 1);
            function frame() {
              if (width <= 0) {
                clearInterval(id);
                document
                  .getElementById('root')
                  ?.style.setProperty('--sidebar-display', 'none');
                sessionStorage.setItem('sidebar', 'false');
              } else {
                width--;
                document
                  .getElementById('root')
                  ?.style.setProperty('--sidebar-width', width + 'rem');
              }
            }
          }}
          style={{
            display: 'block',
            margin: '0.75rem auto',
            marginRight: '1rem',
            height: '1.5rem',
            minWidth: '1.5rem',
            cursor: 'pointer',
            padding: '0',
            border: 'none',
            background: 'transparent',
          }}
        >
          <TbLayoutSidebarLeftCollapse
            style={{
              color: 'var(--text-color)',
              height: '100%',
              minWidth: '100%',
              cursor: 'pointer',
            }}
          />
        </button>
      </div>
      <hr
        style={{
          position: 'absolute',
          width: '100%',
          margin: 0,
          border: '0.5px solid var(--border-color-secondary)',
          zIndex: 10,
          transform: 'translate(0, 3rem)',
        }}
      />
      <div
        style={{
          padding: '1rem',
          marginTop: '3rem',
          display: 'block',
          height: '100%',
          width: '100%',
        }}
      >
        <ul
          style={{
            fontSize: '0.9rem',
            fontWeight: 600,
            color: 'var(--text-color)',
            fontFamily: "Open Sans, 'Times New Roman', Times, serif",
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            padding: '0',
            margin: '0',
            textDecoration: 'underline',
            height: '100%',
          }}
        >
          <li
            style={{
              display: 'block',
              width: '100%',
              height: '2rem',
              paddingTop: '1rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <a
              href="/dashboard"
              style={{
                margin: '0',
                padding: '0',
                fontSize: '0.9rem',
                fontWeight: 600,
                color: 'var(--text-color)',
                fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              Dashboard
            </a>
          </li>
          <li
            style={{
              display: 'block',
              width: '100%',
              height: '2rem',
              paddingTop: '1rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <a
              href="/search"
              style={{
                margin: '0',
                padding: '0',
                fontSize: '0.9rem',
                fontWeight: 600,
                color: 'var(--text-color)',
                fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              Search
            </a>
          </li>
          <li
            style={{
              display: 'block',
              width: '100%',
              height: '2rem',
              paddingTop: '1rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <a
              href="/schemas"
              style={{
                margin: '0',
                padding: '0',
                fontSize: '0.9rem',
                fontWeight: 600,
                color: 'var(--text-color)',
                fontFamily: "Open Sans, 'Times New Roman', Times, serif",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              Schema Explorer
            </a>
          </li>
        </ul>
        <button
          style={{
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
            bottom: '1rem',
            right: '1rem',
            border: 'none',
            background: 'transparent',
            padding: '0',
          }}
          onClick={() => {
            swapTheme(theme === 'light' ? 'dark' : 'light');
            setTheme(theme === 'light' ? 'dark' : 'light');
          }}
        >
          <TbSunFilled
            style={{
              color: 'var(--text-color)',
              height: '100%',
              width: '100%',
              cursor: 'pointer',
            }}
          />
        </button>
      </div>
    </div>
  );
}

function swapTheme(theme: string) {
  switch (theme) {
    case 'light':
      document.body?.style.setProperty('--background-color', '#fff');
      document.body?.style.setProperty('--text-color', '#000');
      document.body?.style.setProperty('--mask-color', '#f5f5f5e0');
      document.body?.style.setProperty('--border-color', '#000');
      document.body?.style.setProperty(
        '--border-color-secondary',
        'rgba(0, 0, 0, 0.2)',
      );
      document.body?.style.setProperty('--message-info-color', '#e8e3ef');
      document.body?.style.setProperty('--message-error-color', '#f8d7d5');
      document.body?.style.setProperty('--message-warn-color', '#f8e8d5');
      sessionStorage.setItem('theme', 'light');
      break;
    case 'dark':
      document.body?.style.setProperty('--background-color', '#000');
      document.body?.style.setProperty('--text-color', '#fff');
      document.body?.style.setProperty('--mask-color', '#050505e0');
      document.body?.style.setProperty('--border-color', '#fff');
      document.body?.style.setProperty(
        '--border-color-secondary',
        'rgba(255, 255, 255, 0.2)',
      );
      document.body?.style.setProperty('--message-info-color', '#665f70');
      document.body?.style.setProperty('--message-error-color', '#75514f');
      document.body?.style.setProperty('--message-warn-color', '#756b4f');
      sessionStorage.setItem('theme', 'dark');
      break;
    default:
      break;
  }
}

export function AppHeader(pagename: string) {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null as any);

  React.useEffect(() => {
    const sidebar = sessionStorage.getItem('sidebar');
    if (sidebar === 'true') {
      document
        .getElementById('root')
        ?.style.setProperty('--sidebar-width', '15rem');
      document
        .getElementById('root')
        ?.style.setProperty('--sidebar-display', 'inline-flex');
    }
    auth.onAuthStateChanged(userInfo => {
      if (userInfo) {
        setUser(userInfo);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <AccountMenu {...{ user }} />
      <Sidebar {...{ pagename }} />
      <div
        style={{
          zIndex: 100,
          display: 'inline-flex',
          flexDirection: 'row',
          maxWidth: '100vw',
          width: '100%',
          height: '3rem',
          background: 'var(--background-color)',
          boxShadow: '0px 0px 15px -5px var(--border-color-secondary)',
        }}
      >
        <button
          id="sidebar-expand"
          onClick={() => {
            var width = 0;
            var id = setInterval(frame, 1);
            document
              .getElementById('root')
              ?.style.setProperty('--sidebar-display', 'inline-flex');
            function frame() {
              if (width >= 15) {
                clearInterval(id);
              } else {
                width++;
                document
                  .getElementById('root')
                  ?.style.setProperty('--sidebar-width', width + 'rem');
              }
            }
            sessionStorage.setItem('sidebar', 'true');
            document
              .getElementById('menu')
              ?.style.setProperty('--menu-display', 'none');
          }}
          style={{
            width: 'auto',
            height: 'auto',
            margin: 'auto 0.5rem',
            marginLeft: '1.5rem',
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
          }}
        >
          <TbLayoutSidebarLeftExpand
            style={{
              color: 'var(--text-color)',
              padding: '0',
              height: '1.5rem',
              width: '1.5rem',
              cursor: 'pointer',
            }}
          />
        </button>
        <AccountWrapper>
          <button
            id="menu-expand"
            onClick={() => {
              if (
                document
                  .getElementById('menu')
                  ?.style.getPropertyValue('--menu-display') === 'block'
              ) {
                document
                  .getElementById('menu')
                  ?.style.setProperty('--menu-display', 'none');
              } else {
                document
                  .getElementById('menu')
                  ?.style.setProperty('--menu-display', 'block');
              }
            }}
            style={{
              width: 'auto',
              height: 'auto',
              margin: 'auto 0.5rem',
              backgroundColor: 'transparent',
              border: 'none',
              padding: 0,
            }}
          >
            <TbMenu2
              style={{
                color: 'var(--text-color)',
                height: '1.5rem',
                width: '1.5rem',
                cursor: 'pointer',
              }}
            />
          </button>
        </AccountWrapper>
        <hr
          style={{
            position: 'absolute',
            width: '100%',
            margin: 0,
            border: '0.5px solid var(--border-color-secondary)',
            zIndex: 10,
            transform: 'translate(0, 3rem)',
          }}
        />
      </div>
    </>
  );
}
