import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { media } from 'styles/media';

import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { AppHeader } from 'app/components/Header/AppHeader';

import './dash.css';

const BodyWrapper = styled.div`
  ${media.small} {
    margin-left: auto;
  }
  ${media.medium} {
    margin-left: var(--sidebar-width);
  }
`;

export function DashboardPage() {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Data Jungle Dashboard Page" />
      </Helmet>
      {AppHeader('Dashboard')}
      <BodyWrapper>
        <div
          style={{
            height: 'calc(100vh - 3rem)',
            width: '100%',
            maxWidth: '70rem',
            padding: '1rem',
            margin: '0 auto',
          }}
        >
          <h1
            style={{
              color: 'var(--text-color)',
              opacity: 1,
              margin: 0,
              fontFamily: 'inherit',
            }}
          >
            Dashboard
          </h1>
        </div>
      </BodyWrapper>
    </>
  );
}
