import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { media } from 'styles/media';
import axios from 'axios';
import { UlidMonotonic } from 'id128';

import { db, auth } from 'utils/firebase-init';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { format } from 'date-fns';

import { AppHeader } from 'app/components/Header/AppHeader';
import { TimeAgo } from 'app/components/TimeAgo';

import { Grid } from 'app/components/Loading/Grid';
import { NewSchema } from './NewSchema';

import { FaExpandAlt } from 'react-icons/fa';

import { fetchSchema } from 'utils/dbUtils';

const BodyWrapper = styled.div`
  padding: 0.5rem;
  font-size: 0.7rem;
  ${media.small} {
    padding: 1rem;
    margin-left: auto;
    font-size: 0.9rem;
  }
  ${media.medium} {
    margin-left: var(--sidebar-width);
  }
`;

export function SchemaPage() {
  const [loading, setLoading] = React.useState(false);
  const [schema, setSchema] = React.useState({});
  const [schemas, setSchemas] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    if (process.env.NODE_ENV === 'development') {
      const mockData = [
        {
          id: '1234567890',
          name: 'Test DB',
          dialect: 'MySQL',
          updated: 'Tue, 13 Jun 2023 03:04:14 GMT',
          time: '3 weeks ago',
          connection: null,
        },
      ];
      setSchemas(mockData as any);
      setLoading(false);
      return;
    }
    auth.currentUser?.getIdToken(false).then(async token => {
      await axios
        .post(
          'https://datajungle.com/schema/list',
          {
            token: token,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(res => {
          if (res.data.schemas.length > 0) {
            res.data.schemas.forEach(schema => {
              const date = new Date((schema as object)['updated']);
              (schema as object)['updated'] = format(
                date,
                'yyyy-MM-dd HH:mm:ss',
              );
              (schema as object)['time'] = TimeAgo(date);
            });
            setSchemas(res.data.schemas);
          }
        });
    });
    setLoading(false);
  }, []);

  const dialects = [
    { name: 'Aurora MySQL', code: 'Aurora MySQL' },
    { name: 'Aurora PostgreSQL', code: 'Aurora PostgreSQL' },
    { name: 'Cassandra', code: 'Cassandra' },
    // { name: 'CockroachDB', code: 'CockroachDB' },
    // { name: 'DB2', code: 'DB2' },
    { name: 'Django ORM', code: 'Django ORM' },
    { name: 'DuckDB', code: 'DuckDB' },
    { name: 'DynamoDB', code: 'DynamoDB' },
    // { name: 'Elasticsearch', code: 'Elasticsearch' },
    // { name: 'Firestore', code: 'Firestore' },
    // { name: 'MariaDB', code: 'MariaDB' },
    { name: 'MongoDB', code: 'MongoDB' },
    { name: 'MySQL', code: 'MySQL' },
    // { name: 'Oracle', code: 'Oracle' },
    { name: 'PostgreSQL', code: 'PostgreSQL' },
    { name: 'Planetscale', code: 'Planetscale' },
    { name: 'Redshift', code: 'Redshift' },
    // { name: 'Snowflake', code: 'Snowflake' },
    { name: 'SQL Server', code: 'SQL Server' },
    { name: 'SQLite', code: 'SQLite' },
  ];

  const [dialect, setDialect] = React.useState(dialects[0]);
  const [showSchema, setShowSchema] = React.useState(false);
  const [showNewSchema, setShowNewSchema] = React.useState(false);

  const selectedDialect = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const dialectOptionTemplate = option => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const PreviewHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color-secondary);
    ${media.mobile} {
      flex-direction: column;
    }
    ${media.small} {
      flex-direction: row;
    }

    .dropdownContainer,
    .schemaTitle {
      ${media.mobile} {
        width: 100%;
      }
      ${media.small} {
        width: min(50%, 20rem);
      }
    }
  `;

  return (
    <>
      <Helmet>
        <title>Schema</title>
        <meta name="description" content="Schema Explorer" />
      </Helmet>
      {AppHeader('Schema')}

      <BodyWrapper>
        <div
          style={{
            minHeight: '3rem',
            width: '100%',
            maxWidth: '50rem',
            margin: '0 auto',
          }}
        >
          <h1
            style={{
              color: 'var(--text-color)',
              float: 'left',
              margin: 0,
              fontFamily: 'inherit',
              width: 'fit-content',
            }}
          >
            Schemas
          </h1>
          <button
            aria-label="New Schema"
            className="p-button-raised p-button-rounded"
            style={{
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem',
              backgroundColor: 'var(--border-color-secondary)',
              color: 'var(--text-color)',
              fontWeight: 'bold',
              fontSize: '1rem',
              border: 'none',
              float: 'right',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowNewSchema(true);
            }}
          >
            New Schema
          </button>
        </div>
        <DataTable
          value={schemas}
          stripedRows
          style={{
            maxHeight: 'calc(100vh - 6rem)',
            maxWidth: '50rem',
            margin: 'auto',
            border: '1px solid var(--border-color-secondary)',
            borderRadius: '0.75rem',
            padding: '0.5rem',
          }}
          onRowMouseEnter={e => {
            // show preview style
            (
              e.originalEvent.currentTarget.children[3]
                .children[0] as HTMLElement
            ).style.visibility = 'visible';
          }}
          onRowMouseLeave={e => {
            // hide preview style
            (
              e.originalEvent.currentTarget.children[3]
                .children[0] as HTMLElement
            ).style.visibility = 'hidden';
          }}
          emptyMessage="No schemas found."
        >
          <Column
            field="name"
            header="Name"
            style={{ padding: '0.5rem' }}
            sortable
            body={data => (
              <div
                style={{
                  fontWeight: 'bold',
                  maxWidth: '60vw',
                }}
                title={data.name}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data.name}
                </div>
              </div>
            )}
          ></Column>
          <Column field="dialect" header="Dialect" sortable></Column>
          <Column
            field="updated"
            header="Updated"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            body={data => (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                title={
                  format(
                    new Date(
                      data.updated.toLocaleString({ timeZoneName: 'short' }),
                    ),
                    'EEE, MMM dd, yyyy HH:mm a',
                  ) +
                  ' ' +
                  new Date()
                    .toLocaleString('en-US', { timeZoneName: 'long' })
                    .split(' ')[3]
                }
              >
                {data.time}
              </div>
            )}
            sortable
          ></Column>
          <Column
            field="preview"
            style={{ width: '1.5rem' }}
            body={data => (
              <button
                id={data.name}
                style={{
                  color: 'var(--text-color)',
                  visibility: 'hidden',
                  padding: '0.2rem',
                  backgroundColor: 'transparent',
                  border: 'none',
                  position: 'absolute',
                  transform: 'translate(0, -50%)',
                  margin: 'auto 0',
                  cursor: 'pointer',
                }}
                title="Preview"
                className="p-button-raised p-button-rounded"
                onClick={() => {
                  fetchSchema(window.localStorage.getItem('app')!, data.id)
                    .then(res => {
                      setSchema(res);
                      setDialect(
                        dialects.filter(
                          dialect => dialect.name === res.dialect,
                        )[0],
                      );
                      setShowSchema(data.name);
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
              >
                <FaExpandAlt />
              </button>
            )}
          ></Column>
        </DataTable>
        <Dialog
          className="overflow-auto"
          style={{
            borderRadius: '1rem',
            zIndex: 1000,
          }}
          header={'Edit Schema'}
          maximized
          draggable={false}
          dismissableMask={true}
          blockScroll={true}
          visible={showSchema}
          onHide={() => {
            setShowSchema(false);
          }}
        >
          <PreviewHeader>
            <InputText
              className="schemaTitle"
              defaultValue={schema['name']}
              onChange={e => {
                setSchema(schema => ({ ...schema, name: e.target.value }));
              }}
              style={{
                padding: '0.5rem',
                color: 'var(--text-color)',
                fontFamily: 'inherit',
                fontSize: '1rem',
                fontWeight: 'bold',
                border: '1px solid var(--border-color)',
                borderRadius: '0.5rem',
                backgroundColor: 'transparent',
                textOverflow: 'ellipsis',
              }}
            />
            <div className="dropdownContainer">
              <Dropdown
                value={dialect}
                onChange={e => {
                  setSchema(schema => ({ ...schema, dialect: e.value.code }));
                  setDialect(e.value);
                }}
                options={dialects}
                optionLabel="dialect"
                placeholder="Select a Query Language"
                required
                valueTemplate={selectedDialect}
                itemTemplate={dialectOptionTemplate}
                style={{
                  borderRadius: '0.5rem',
                  border: '1px solid var(--border-color)',
                  padding: '0.2rem 0.8rem',
                  color: 'var(--text-color)',
                  width: '100%',
                  margin: '0.5rem 0',
                }}
              />
            </div>
          </PreviewHeader>
          <InputTextarea
            autoResize
            value={schema['schema']}
            onChange={e =>
              setSchema(schema => ({ ...schema, schema: e.target.value }))
            }
            rows={5}
            cols={30}
            style={{
              color: 'var(--text-color)',
              width: '100%',
              height: '100%',
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              fontSize: '0.8rem',
              padding: '0.5rem',
              border: 'none',
              borderBottom: '1px solid var(--border-color-secondary)',
              borderRadius: '0',
              backgroundColor: 'transparent',
            }}
          />
          <button
            aria-label="Save"
            className="p-button-raised p-button-rounded "
            style={{
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem',
              backgroundColor: 'var(--border-color-secondary)',
              color: 'var(--text-color)',
              fontWeight: 'bold',
              fontSize: '1rem',
              border: 'none',
              float: 'right',
              cursor: 'pointer',
            }}
            onClick={() => {
              setLoading(true);
              auth.currentUser?.getIdToken(false).then(async token => {
                await axios
                  .post(
                    'https://datajungle.com/schema/update',
                    {
                      token: token,
                      id: schema['id'],
                      name: schema['name'],
                      dialect: schema['dialect'],
                      schema: schema['schema'],
                      connection: schema['connection'],
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    },
                  )
                  .then(res => {
                    if (res.status === 200) {
                      setShowSchema(false);
                    }
                  });
                setLoading(false);
              });
            }}
          >
            Save
          </button>
        </Dialog>
        <Dialog
          className="overflow-auto"
          style={{
            borderRadius: '1rem',
            zIndex: 1000,
          }}
          header={'New Schema'}
          maximized
          draggable={false}
          dismissableMask={true}
          blockScroll={true}
          visible={showNewSchema}
          onHide={() => {
            setShowNewSchema(false);
          }}
        >
          {!loading && <NewSchema />}
          {loading && (
            <div className="mx-auto w-full mt-4" style={{ maxWidth: '50rem' }}>
              <Grid text={'Setting schema...'} />
            </div>
          )}
        </Dialog>
      </BodyWrapper>
    </>
  );
}
