export function TimeAgo(date: Date | string) {
  typeof date === 'string' && (date = new Date(date));
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const diffYears = Math.floor(diff / (1000 * 3600 * 24 * 7 * 4 * 12));
  const diffMonths = Math.floor(diff / (1000 * 3600 * 24 * 7 * 4));
  const diffWeeks = Math.floor(diff / (1000 * 3600 * 24 * 7));
  const diffDays = Math.floor(diff / (1000 * 3600 * 24));
  const diffHours = Math.floor(diff / (1000 * 3600));
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffSeconds = Math.floor(diff / 1000);
  let response = '';
  switch (true) {
    case diffYears > 0:
      response = `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
      break;
    case diffMonths > 0:
      response = `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
      break;
    case diffWeeks > 0:
      response = `${diffWeeks} week${diffWeeks > 1 ? 's' : ''} ago`;
      break;
    case diffDays > 0:
      response = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
      break;
    case diffHours > 0:
      response = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
      break;
    case diffMinutes > 0:
      response = `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
      break;
    case diffSeconds > 0:
      response = `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`;
      break;
    default:
      response = 'A moment ago';
      break;
  }
  return response;
}
