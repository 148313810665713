import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { media } from 'styles/media';

import 'primereact/resources/themes/md-dark-deeppurple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';

import { Footer } from 'app/components/Footer/Footer';
import { Header } from 'app/components/Header/Header';

export function SolutionsPage() {
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Data Jungle Home Page" />
      </Helmet>
      <Header {...{ showAccount: true }} />
      <div
        style={{
          height: 'calc(100vh - 5rem)',
          position: 'absolute',
          width: '100%',
        }}
      >
        <div
          style={{
            height: 'calc(100vh - 5rem)',
            width: '100%',
          }}
        ></div>
        <Footer />
      </div>
    </>
  );
}
