// UI element that show password requirements and show checkmarks when requirements are met

import React from 'react';

export function PasswordCheck({
  password,
  shown,
}: {
  password: string;
  shown: boolean;
}) {
  const [passwordCheck, setPasswordCheck] = React.useState(false);

  React.useEffect(() => {
    if (password.length > 7) {
      setPasswordCheck(true);
    } else {
      setPasswordCheck(false);
    }
  }, [password]);

  return (
    <>
      {shown && (
        <div className="p-grid p-justify-center">
          <div className="p-col-12 p-md-6">
            <div
              className="password-card"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                fontFamily: 'Playfair Display',
                fontSize: '0.9rem',
                margin: '0.5rem',
              }}
            >
              <p style={{ margin: '0.1rem 0', minWidth: '50%' }}>
                {passwordCheck ? (
                  <span style={{ color: 'green' }}>&#10003; 8 Characters</span>
                ) : (
                  <span style={{ color: 'red' }}>&#10005; 8 Characters</span>
                )}
              </p>
              <p style={{ margin: '0.1rem 0', minWidth: '50%' }}>
                {password.match(/[0-9]/) ? (
                  <span style={{ color: 'green' }}>&#10003; Number(s)</span>
                ) : (
                  <span style={{ color: 'red' }}>&#10005; Number(s)</span>
                )}
              </p>
              <p style={{ margin: '0.1rem 0', minWidth: '50%' }}>
                {password.match(/[a-z]/) ? (
                  <span style={{ color: 'green' }}>
                    &#10003; Lowercase letter(s)
                  </span>
                ) : (
                  <span style={{ color: 'red' }}>
                    &#10005; Lowercase letter(s)
                  </span>
                )}
              </p>
              <p style={{ margin: '0.1rem 0', minWidth: '50%' }}>
                {password.match(/[A-Z]/) ? (
                  <span style={{ color: 'green' }}>
                    &#10003; Uppercase letter(s)
                  </span>
                ) : (
                  <span style={{ color: 'red' }}>
                    &#10005; Uppercase letter(s)
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
