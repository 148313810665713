import { UlidMonotonic } from 'id128';
import axios from 'axios';
import CryptoJS from 'crypto-js';

import { UserModel } from './model';

import { auth } from './firebase-init';

export async function createUser(
  uid: string,
  email: string,
  firstName: string,
  lastName: string | null,
  phone: string | null,
  employee_id: string | null,
) {
  const user: UserModel = {
    email: email,
    first_name: firstName,
    last_name: lastName || null,
    phone: phone,
    user_id: uid,
    account: {
      type: 'free',
      status: 'active',
      employee_id: employee_id,
    },
    meta: {
      create_date: new Date().toUTCString(),
      update_date: new Date().toUTCString(),
      is_active: true,
    },
  };

  const ulid = UlidMonotonic.generate();
  const response = await axios.post(
    'https://datajungle.com/account/create',
    {
      ulid: ulid.toRaw(),
      user: user,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    },
  );
  if (response.status !== 200) {
    const resp = { error: 'Error getting response, please try again.' };
    return resp;
  }
  return response.data;
}

export async function listSchema() {
  const schemas: any[] = [];
  // console.time('listSchemas');
  await listSchemas()
    .then(res => {
      if (res.schemas) {
        res.schemas.forEach(schema => {
          schemas.push(schema);
        });
      }
    })
    .catch(err => {});
  // console.timeEnd('listSchemas');
  return { schemas: schemas };
}

export const fetchSchema = async (handle, schemaId) => {
  if (process.env.NODE_ENV === 'development') {
    const mockData = {
      id: '1234567890',
      name: 'Test DB',
      dialect: 'MySQL',
      updated: 'Tue, 13 Jun 2023 03:04:14 GMT',
      time: '3 weeks ago',
      connection: null,
    };
    return mockData;
  }
  return await auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/schema/fetch',
      {
        token: token,
        id: schemaId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error getting response, please try again.' };
      return resp;
    }
    return response.data;
  });
};

export async function listSchemas(): Promise<{ schemas: any[] }> {
  console.time('listSchemas');
  auth.currentUser?.getIdToken(false).finally(() => {
    console.timeEnd('listSchemas');
  });
  return await auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/schema/list',
      {
        token: token,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error getting response, please try again.' };
      return resp;
    }
    return response.data;
  });
}

export async function createSchema(name: string, dialect: any, schema: any) {
  const ulid = UlidMonotonic.generate().toRaw();
  return await auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/schema/create',
      {
        name: name,
        id: ulid,
        token: token,
        dialect: dialect.name,
        schema: schema,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error getting response, please try again.' };
      return resp;
    }
    return response.data;
  });
}

export async function updateSchema(
  handle: string,
  schemaId: string,
  schema: any,
) {
  return await auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/schema/update',
      {
        token: token,
        id: schemaId,
        schema: schema,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error getting response, please try again.' };
      return resp;
    }
    return response.data;
  });
}

export async function queryClient(
  query: string,
  schemaId: string,
  dialect: string,
  connection: object,
) {
  if (process.env.NODE_ENV === 'development') {
    const mockData = {
      columns: [
        {
          name: 'id',
          type: 'int',
        },
        {
          name: 'name',
          type: 'varchar',
        },
      ],
      rows: [
        {
          id: 1,
          name: 'test',
        },
      ],
      rowCount: 1,
      executionTime: 0.1,
      error: null,
    };
    return mockData;
  }
  return await auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/db/query',
      {
        token: token,
        query: query,
        schema_id: schemaId,
        dialect: dialect,
        connection: connection,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error getting response, please try again.' };
      return resp;
    }
    return response.data;
  });
}

export async function createConnection(
  name: string,
  host: string,
  port: string,
  tls: boolean,
  dialect: string,
  database: string,
  username: string,
  password: string,
) {
  const ulid = UlidMonotonic.generate();
  auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/app/connection/create',
      {
        id: ulid.toRaw(),
        token: token,
        app: JSON.parse(window.localStorage.getItem('app') || '{}'),
        name: name,
        host: host,
        port: port,
        tls: tls,
        dialect: dialect,
        database: database,
        username: username,
        password: password,
      },
      {
        headers: {
          ContentType: 'application/json',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error adding connection. Please try again.' };
      return resp;
    }
    return response.data;
  });
}

function generateSigned(documentPath: string): string {
  const bucket_id = 'drop-source-bucket';
  const object_name = documentPath;

  const privateKey =
    '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC+5ZrSZH5lvHdw\nW99jyKwXv7qvpM1ZINx25hKl5eVGVOqpYXClbtUQ5iIr8F6IBlcHcvrC8ro5uMJ/\nAggQ6xSToruueL+NqJ47v4+R8gW5qSvYRVTdsVml9gbcj49BNYW1gMcUJvjxERbB\neGEovkCK6sFW4PDjH4KxpVEimRkKbPq/UpjffncX42gNgSOxFTGiz16DJ6wEDdnh\noXlCV4PDnrJWhkz8hjncjEnQVwl2pzVgw7XtpbLbtll1eIso4bFumbMMPshTpSf6\nML0pGKXx8gh+mcfQNQgWB+J7SDkfdCTadv5AT07BUVVsYA3ksltpqe5kal3XMwVp\nmnvDQSfbAgMBAAECggEATzeAs+Y3/YfkGKBHZ2US5RgPJGxDOsVEN6LqAiYQqsr6\nRifFUyFXEZgufgVVdSUMYG76WIPZe7KMI6B4MG0dMNbLA2/gLazXXPv1XB8O+SiW\npTzAJu89wH0EA+sfC3RmdBdohZhWndL1mnuV9g5dQkiAysi1rcqZtZZgwPa/SW+Q\nZrcov61SwZ6koABGGUQsL6Y1Nq1xfotm9Xf4ip/orTA32cClsM1diEpe73SprevA\n6lApYg0z2WWM5uAPlDAe6WCFPF2+lSlfQFXmJUWaegvxAbgym7ZukUwvIivpNuLr\nUovLPKvHtBl6pgjeCnTJFRuzwH30R2XivYIwa5H1wQKBgQDimboN+9uv6SwAUBVL\nNp96kwr4YwEUYHXIaisfq3kySogW3oG/KDclKOnDuQpLh9IEpfrLvVW8Auuzw7m9\nzdE7gEnMFJ2hzoJyUMhwfAWTggQviwCXqoKebJekdtIiVKo+tBZhZih8HlD12w3n\nMSGmCbv59UoXUsCB/KLZDAMUgwKBgQDXqgYxIukNc8IT0zayssRIShGKfVqDfrlD\nf64+wZmSOaKgpXW0j2neFwA/lpG/ml1QwKYDghHIK7oiPr7HcSGtJzXlt2ddFsa4\nbpVPBLHHszqq7KiypuktAY6pV8IAoEcSt3+ybDcfcvu3Vyb2gzFWvGWbTv4Kv/5M\nHqQkQwYvyQKBgQDIMsMTSbntL9elKxu1CLxbggAGNQomI5bMCyiwYUHBceHMOqds\n6K3X0c6+EgSLeKLPYokyacOOSRJPDxRSIb3CkPQiLvCZnSbYspBz+M7AZPUnv+o1\nFPsz1oMfVa8Pj7edkGPZh3UrO2mR3QfqgxjUZfNCwjjEZGMaNMb3dcpe2wKBgBa4\nGp7ihpqcLz3XalO48WSMKPzl57sihnHN6ZVC3+K/n5o81AGJeiWjVqtcOU5t/Jad\nNN/5u9XShXFGGuL30AxlrQ/eGk18MMMNIz2HyRK4lt2awMrROo87MsQ0MoPOXq5i\nFlm9fLEbc9kEnV4qw+/XOPz7fVe0kh2CvNf1F5nBAoGAJAdUSDJ8dBGDu+VRbhxX\nYsEQmr+ZeVH76jjppYVQBUukKEDKi+aJWDubq+/k6y/iWSeR7V7E5ydpxyKY08Xd\n3c6/CCO9BzWjIdySW7fvkAm9Zgn9Kv40hsKezGWpLJ8d4Hb2UssK3TRMDCyOZDBL\nYz/qv0BMX02HLxdbrxEEkWw=\n-----END PRIVATE KEY-----\n';

  const object_uri = `${bucket_id}.appspot.com/${object_name}`;
  const request_timestamp =
    new Date()
      .toISOString()
      .replaceAll('-', '')
      .replaceAll(':', '')
      .substring(0, 15) + 'Z';
  //const request_timestamp = '20230614T150520Z'
  const datestamp = request_timestamp.substring(0, 8);
  const service_account = bucket_id + '@appspot.gserviceaccount.com';
  const credential_scope = `${datestamp}/auto/storage/goog4_request`;
  const credential = `${service_account}/${credential_scope}`;

  const expiration = 3600;

  const canonical_query = [
    'x-goog-algorithm=GOOG4-RSA-SHA256',
    `x-goog-credential=${encodeURIComponent(credential)}`,
    `x-goog-date=${encodeURIComponent(request_timestamp)}`,
    `x-goog-expires=${encodeURIComponent(expiration)}`,
    'x-goog-signedheaders=host',
  ].join('&');

  const canonical_request = `PUT
/${object_uri}
${canonical_query}
host:storage.googleapis.com

host
UNSIGNED-PAYLOAD`;

  const canonical_request_hash = CryptoJS.SHA256(canonical_request).toString(
    CryptoJS.enc.Hex,
  );

  const sign_string = `GOOG4-RSA-SHA256
${request_timestamp}
${credential_scope}
${canonical_request_hash}`;

  const signature = CryptoJS.HmacSHA256(sign_string, privateKey).toString(
    CryptoJS.enc.Hex,
  );

  const signedUrl = `https://storage.googleapis.com/${object_uri}?${canonical_query}&x-goog-signature=${signature}`;

  console.log(signedUrl);
  return signedUrl;
}

export async function createSource(
  name: string,
  documentPath: string,
  user: any,
  sourceType: string,
) {
  const signed = generateSigned(documentPath);

  const response = await axios.get(signed, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });

  console.log(response);

  const ulid = UlidMonotonic.generate();
  auth.currentUser?.getIdToken(false).then(async token => {
    const response = await axios.post(
      'https://datajungle.com/app/source/create',
      {
        id: ulid.toRaw(),
        token: token,
        app: JSON.parse(window.localStorage.getItem('app') || '{}'),
        name: name,
        document_path: documentPath,
        user: user,
        source_type: sourceType,
      },
      {
        headers: {
          ContentType: 'application/json',
        },
      },
    );
    if (response.status !== 200) {
      const resp = { error: 'Error adding source. Please try again.' };
      return resp;
    }
    return response.data;
  });
}
