import { lazyLoad } from 'utils/loadable';

export const AccountPage = lazyLoad(
  () => import('./index'),
  module => module.AccountPage,
);

// export const AccountPage = () => {
//   window.location.href = '/signin';
//   return null;
// };
