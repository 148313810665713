import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { media } from 'styles/media';
import axios from 'axios';
import { UlidMonotonic } from 'id128';

import {
  doc,
  setDoc,
  getDoc,
  addDoc,
  updateDoc,
  getDocs,
  query,
  collection,
  where,
} from 'firebase/firestore';

import { db, auth } from 'utils/firebase-init';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { BsSearch } from 'react-icons/bs';

import { AppHeader } from 'app/components/Header/AppHeader';

import { Grid } from 'app/components/Loading/Grid';

const SchemaInput = styled.div`
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;
  margin-top: 1rem;
  .schemaName,
  .searchButton {
    text-overflow: ellipsis;
    padding: 0.5rem;
    font-size: 1rem;
  }
  .searchButton {
    display: inline-block;
    background-color: var(--text-color) !important;
    color: var(--background-color) !important;
    cursor: pointer;
    width: 100%;
  }
  .schemaName {
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
  }
  ${media.small} {
    .schemaName,
    .searchButton {
      padding: 0.8rem;
      font-size: 1.1rem;
    }
  }
  ${media.medium} {
    .schemaName,
    .searchButton {
      padding: 0.8rem;
      font-size: 1.2rem;
    }
  }
`;

export function NewSchema() {
  const [appName, setAppName] = React.useState('rvgapp');
  const [loading, setLoading] = React.useState(false);
  const [connections, setConnections] = React.useState(undefined);
  const [name, setName] = React.useState('');
  const [schema, setSchema] = React.useState('');

  const dialects = [
    { name: 'Aurora MySQL', code: 'Aurora MySQL' },
    { name: 'Aurora PostgreSQL', code: 'Aurora PostgreSQL' },
    { name: 'Cassandra', code: 'Cassandra' },
    // { name: 'CockroachDB', code: 'CockroachDB' },
    // { name: 'DB2', code: 'DB2' },
    { name: 'Django ORM', code: 'Django ORM' },
    { name: 'DuckDB', code: 'DuckDB' },
    { name: 'DynamoDB', code: 'DynamoDB' },
    // { name: 'Elasticsearch', code: 'Elasticsearch' },
    // { name: 'Firestore', code: 'Firestore' },
    // { name: 'MariaDB', code: 'MariaDB' },
    { name: 'MongoDB', code: 'MongoDB' },
    { name: 'MySQL', code: 'MySQL' },
    // { name: 'Oracle', code: 'Oracle' },
    { name: 'PostgreSQL', code: 'PostgreSQL' },
    { name: 'Planetscale', code: 'Planetscale' },
    { name: 'Redshift', code: 'Redshift' },
    // { name: 'Snowflake', code: 'Snowflake' },
    { name: 'SQL Server', code: 'SQL Server' },
    { name: 'SQLite', code: 'SQLite' },
  ];

  const [selectedConnection, setSelectedConnection] = React.useState(undefined);
  const [dialect, setDialect] = React.useState(dialects[0]);
  const selectedDialect = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const dialectOptionTemplate = option => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
      auth.currentUser?.getIdToken(false).then(async token => {
        await axios
          .post(
            'https://datajungle.com/app/connection/list',
            {
              token: token,
            },
            {
              headers: {
                ContentType: 'application/json',
              },
            },
          )
          .then(response => {
            setConnections(response.data);
          })
          .catch(error => {
            console.log(error);
          });
      });
      setLoading(false);
    } else {
      setConnections(undefined);
    }
  }, [loading]);

  return (
    <>
      <SchemaInput>
        <form
          onSubmit={async e => {
            e.preventDefault();
            setLoading(true);
            const ulid = UlidMonotonic.generate().toRaw();
            return await auth.currentUser
              ?.getIdToken(false)
              .then(async token => {
                const response = await axios.post(
                  'https://datajungle.com/schema/write',
                  {
                    name: name,
                    id: ulid,
                    token: token,
                    dialect: dialect.name,
                    schema: schema,
                    connection: selectedConnection,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  },
                );
                if (response.status !== 200) {
                  console.log(response);
                }
                setName('');
                setSchema('');
                setDialect(dialects[0]);
                setLoading(false);
              });
          }}
        >
          <InputText
            name="schema_name"
            value={name}
            autoComplete="off"
            placeholder="Schema Name"
            required
            onChange={e => {
              setName(e.currentTarget.value);
            }}
            className="w-full schemaName"
            style={{
              backgroundColor: 'var(--background-color)',
              color: 'var(--text-color)',
              fontSize: '1rem',
            }}
          />
          <Dropdown
            value={dialect}
            onChange={e => {
              if (e.value !== dialect) {
                setDialect(e.value);
              }
            }}
            options={dialects}
            optionLabel="dialect"
            placeholder="Select a Query Language"
            required
            valueTemplate={selectedDialect}
            itemTemplate={dialectOptionTemplate}
            style={{
              borderRadius: '0.5rem',
              border: '1px solid var(--border-color)',
              padding: '0.2rem 0.8rem',
              color: 'var(--text-color)',
              width: '100%',
              margin: '0.5rem 0',
            }}
          />
          <Dropdown
            value={selectedConnection}
            onChange={e => {
              if (e.value !== selectedConnection) {
                setSelectedConnection(e.value);
              }
              console.log(e.value);
            }}
            options={connections}
            optionLabel="connection"
            placeholder="Add a Data Source (Optional)"
            valueTemplate={selectedDialect}
            itemTemplate={dialectOptionTemplate}
            style={{
              borderRadius: '0.5rem',
              border: '1px solid var(--border-color)',
              padding: '0.2rem 0.8rem',
              color: 'var(--text-color)',
              width: '100%',
              margin: '0.5rem 0',
            }}
          />
          <InputTextarea
            name="description"
            value={schema}
            placeholder={'"Table Name" (\n  "Column Name" "Data Type",\n)'}
            autoResize
            required
            onChange={e => {
              setSchema(e.currentTarget.value);
            }}
            className="w-full schemaName"
            style={{
              backgroundColor: 'var(--background-color)',
              color: 'var(--text-color)',
              fontFamily: 'monospace',
              minHeight: '25rem',
              maxHeight: '35rem',
              fontSize: '1rem',
            }}
          />
          <Button
            label="Submit"
            type="submit"
            className="schemaName searchButton"
          />
        </form>
      </SchemaInput>
      {loading && (
        <div className="mx-auto w-full mt-4" style={{ maxWidth: '50rem' }}>
          <Grid text={'Setting schema...'} />
        </div>
      )}
      <div className="mx-auto w-full mt-4" style={{ maxWidth: '50rem' }}>
        {!loading && schema && name && (
          <p className="text-2xl font-bold" style={{ fontFamily: 'Open Sans' }}>
            {name} - Preview
          </p>
        )}
        {/* {!loading && schema && !name && (
          <p className="text-2xl font-bold" style={{ fontFamily: 'Open Sans' }}>
            Preview
          </p>
        )}
        {!loading && schema && dialect && (
          <>
            <p className="text-md" style={{ fontFamily: 'Open Sans' }}>
              Dialect: {dialect.name}
            </p>
            <hr />
          </>
        )}
        {!loading && schema && (
          <div className="flex justify-end bg-gray-800 p-4">
            <code
              className="text-sm"
              id="schema_preview"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {schema}
            </code>
          </div>
        )} */}
      </div>
    </>
  );
}
