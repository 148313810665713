import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../../components/Title';
import { SubTitle } from '../../../components/SubTitle';
import { Form, FormSection, FormLink } from '../../../components/FormField';
import { StageCenter } from 'app/components/StageCenter/StageCenter';
import { BsGoogle } from 'react-icons/bs';
import { useNavigate, NavigateFunction, useParams } from 'react-router-dom';

import { createUser } from 'utils/dbUtils';

import { auth } from 'utils/firebase-init';

import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { PasswordCheck } from 'app/components/PasswordCheck/Password';

const provider = new GoogleAuthProvider();

const GoogleLogin = async (navigate: NavigateFunction) => {
  try {
    signInWithPopup(auth, provider).then(result => {
      createUser(
        result.user.uid,
        result.user.email ? result.user.email : 'no email',
        result.user.displayName
          ? result.user.displayName.split(' ')[0]
          : 'User',
        result.user.displayName ? result.user.displayName.split(' ')[1] : null,
        null,
        null,
      );
      navigate('/getstarted');
    });
  } catch (e) {
    console.log(e);
  }
};

export function SignUp() {
  const emailParam = useParams()['passed']?.split('=')[1];
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState(emailParam ? emailParam : '');
  const [password, setPassword] = useState('');
  const [shown, setShown] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        const user = userCredential.user;
        createUser(user.uid, email, firstName, null, null, null)
          .then(() => {
            navigate('/getstarted');
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
        <meta name="description" content="the syathen sign up page" />
      </Helmet>
      <StageCenter>
        <Title>Create an Account.</Title>
        <SubTitle>It only takes a minute.</SubTitle>
        <Form onSubmit={handleSubmit}>
          <FormSection>
            <input
              type="text"
              placeholder="Enter your first name"
              id="first-name"
              name="first-name"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value);
              }}
              onBlur={e => {
                // check regex
                const regex = new RegExp('^[a-zA-Z]{1,20}$');
                if (!regex.test(e.target.value)) {
                  e.target.classList.add('error');
                  e.target.setCustomValidity(
                    'Please enter a valid first name.',
                  );
                  document
                    .querySelector('.error-first-name')
                    ?.classList.add('show');
                } else {
                  e.target.classList.remove('error');
                  e.target.setCustomValidity('');
                  document
                    .querySelector('.error-first-name')
                    ?.classList.remove('show');
                }
              }}
              required
            />
            <p
              className="error-first-name"
              style={{
                display: 'none',
                margin: 0,
                fontFamily: 'Playfair Display',
                fontSize: '0.9rem',
              }}
            >
              Please enter a valid first name.
            </p>
          </FormSection>
          <FormSection>
            <input
              type="text"
              placeholder="Enter your email"
              id="email"
              name="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                e.target.validity.valid &&
                  document
                    .querySelector('.error-email')
                    ?.classList.remove('show');
              }}
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              onBlur={e => {
                // check regex
                const regex = new RegExp(
                  '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
                );
                if (!regex.test(e.target.value)) {
                  e.target.classList.add('error');
                  e.target.setCustomValidity(
                    'Please enter a valid email address.',
                  );
                  document.querySelector('.error-email')?.classList.add('show');
                } else {
                  e.target.classList.remove('error');
                  e.target.setCustomValidity('');
                  document
                    .querySelector('.error-email')
                    ?.classList.remove('show');
                }
              }}
              required
            />
            <p
              className="error-email"
              style={{
                display: 'none',
                margin: 0,
                fontFamily: 'Playfair Display',
                fontSize: '0.9rem',
              }}
            >
              Please enter a valid email address.
            </p>
          </FormSection>
          <FormSection>
            <input
              type="password"
              placeholder="Create a password"
              id="password"
              name="password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
                e.target.validity.valid &&
                  document
                    .querySelector('.error-password')
                    ?.classList.remove('show');
              }}
              onBlur={e => {
                const complexity = new RegExp(
                  '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                );
                const length = new RegExp('.{8,}');
                if (!complexity.test(e.target.value)) {
                  e.target.classList.add('error');
                  e.target.setCustomValidity(
                    'Password must have numbers, lowercase letters, and uppercase letters.',
                  );
                } else if (!length.test(e.target.value)) {
                  e.target.classList.add('error');
                  e.target.setCustomValidity(
                    'Password must be at least 8 characters long.',
                  );
                } else {
                  e.target.classList.remove('error');
                  e.target.setCustomValidity('');
                  setShown(false);
                }
              }}
              onFocus={() => {
                setShown(true);
              }}
              required
            />
          </FormSection>
          <PasswordCheck {...{ password, shown }} />
          <FormSection style={{ marginTop: '1.5rem' }}>
            <input type="submit" value="Sign Up" />
          </FormSection>
        </Form>
        <FormSection>
          <button
            className="google"
            onClick={() => {
              GoogleLogin(navigate);
            }}
          >
            {' '}
            <BsGoogle className="icon" /> Sign up with Google
          </button>
        </FormSection>
        <FormLink href="/signin" title="sign in." rel="noopener noreferrer">
          Have an account? Sign In.
        </FormLink>
      </StageCenter>
    </>
  );
}
