import React, { createContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { AccountPage } from './pages/Account/Loadable';
import { SourcesPage } from './pages/Application/Sources/Loadable';
import { SignIn } from './pages/Account/SignIn';
import { SignUp } from './pages/Account/SignUp';
import { ResetPassword } from './pages/Account/Reset/Loadable';
import { SearchPage } from './pages/Application/SearchPage/Loadable';
import { SchemaPage } from './pages/Application/SchemaPage';
import { DashboardPage } from './pages/Application/Dashboard';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { GetStartedPage } from './pages/Onboarding/GetStarted/Loadable';
import { JoinPage } from './pages/Onboarding/Join/Loadable';
import { FeaturesPage } from './pages/HomePage/Features/Loadable';

import { useTranslation } from 'react-i18next';

import { auth } from 'utils/firebase-init';
import { User } from 'firebase/auth';
import { SolutionsPage } from './pages/HomePage/Solutions';

export const AppContext = createContext({} as any);

function PrivateRoute() {
  const [user, setUser] = React.useState(null as User | null | 'None');
  const [loading, setLoading] = React.useState(
    false as true | false | 'Loaded',
  );

  React.useEffect(() => {
    if (!user && !loading) {
      setLoading(true);
      auth.onAuthStateChanged(userState => {
        setUser(userState || 'None');
        userState?.getIdTokenResult().then(idTokenResult => {
          // Check if app is set in local storage and if it matches the app in the token
          if (
            window.localStorage.getItem('app') !==
            JSON.stringify(idTokenResult.claims.app)
          )
            window.localStorage.setItem(
              'app',
              JSON.stringify(idTokenResult.claims.app),
            );
        });
      });
      setLoading('Loaded');
    }
  }, [user, loading]);

  if (loading === 'Loaded') {
    if (user === 'None') {
      return <Navigate to="/signin" replace />;
    }
    return (
      <>
        {!window.localStorage.getItem('app') && (
          <Navigate to="/getstarted" replace />
        )}
        {user && window.localStorage.getItem('app') && <Outlet />}
      </>
    );
  }
  return <></>;
}

function RedirectApp(params: any) {
  const [user, setUser] = React.useState(null as User | null | 'None');
  const [app, setApp] = React.useState(null as string | null);
  React.useEffect(() => {
    if (user === null) {
      auth.onAuthStateChanged(userState => {
        if (userState) {
          setUser(userState);
          userState.getIdTokenResult().then(idTokenResult => {
            setApp(idTokenResult.claims.app);
          });
        } else {
          setUser('None');
        }
      });
    }
  }, [user]);
  return (
    <>
      {user === 'None' && user !== null && <Navigate to="/signin" replace />}
      {user && <Navigate to={`/${params.page}/${app}`} replace />}
    </>
  );
}

export function App() {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(null);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Data Jungle"
        defaultTitle="Data Jungle"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Data Jungle" />
      </Helmet>

      <AppContext.Provider value={{ user: user, setUser: setUser }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<HomePage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup/:passed" element={<SignUp />} />
          <Route path="/signup/" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ResetPassword />} />
          <Route element={<PrivateRoute />}>
            <Route path="/search" element={<RedirectApp page="search" />} />
            <Route path="/search/:handle" element={<SearchPage />} />
            <Route path="/schemas" element={<RedirectApp page="schemas" />} />
            <Route path="/schemas/:handle" element={<SchemaPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/sources" element={<SourcesPage />} />
            <Route path="/getstarted" element={<GetStartedPage />} />
            <Route path="/join" element={<JoinPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AppContext.Provider>
      <GlobalStyle />
    </BrowserRouter>
  );
}
